<template>
  <div class="navbar-container d-flex content align-items-center">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">
      <dark-Toggler class="d-none d-lg-block" />
    </div>


    <b-navbar-nav class="nav align-items-center ml-auto">
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">
              {{ teacher.name }}
            </p>
            <span class="user-status" />
          </div>
          <b-avatar
            size="40"
            variant="light-primary"
            badge
            :src="require('@/assets/images/avatars/user.jpeg')"
            class="badge-minimal"
            badge-variant="success"
          />
        </template>

        <b-dropdown-divider />

        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="out"
        >
          <feather-icon
            size="16"
            icon="LogOutIcon"
            class="mr-50"
          />
          <span>退出</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink, BNavbarNav, BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar, BSpinner,
} from 'bootstrap-vue'
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
import ReconnectingWebSocket from 'reconnecting-websocket'

export default {
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    BSpinner,
    // Navbar Components
    DarkToggler,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  data(){
    return {
      teacher:{
        name:""
      }
    }

  },
  mounted() {
    this.getTeacher()
    //this.socketInit()
  },
  methods: {

    getTeacher(){
      const teacher = localStorage.getItem("teacher")

      this.teacher = JSON.parse(teacher)

      console.log(teacher)
      // console.log(JSON.parse(student))
      // this.student = JSON.parse(student)
    },

    out() {
      localStorage.clear()
      this.$router.push({ name: 'login' })
    },

    socketInit() {
      if (typeof (WebSocket) === 'undefined') {
        alert('您的浏览器不支持socket')
      } else {
        // 实例化socket
        const addr = 'ws://localhost:8088/socket?group=1&token='
        const token = localStorage.getItem('token')
        if (token.length !== 0) {
          this.socket = new ReconnectingWebSocket(addr + token)
        }
        // 监听socket连接
        this.socket.onopen = this.open
        // 监听socket错误信息
        this.socket.onerror = this.error
        // 监听socket消息
        this.socket.onmessage = this.getMessage
      }
    },
    open() {
      this.showToast(0, '与服务器建立不间断链接完成')

      console.log('socket连接成功')
    },
    error() {
      // this.socketInit()
      console.log('连接错误')
    },
    getMessage(msg) {
      console.log(msg)
      const obj = JSON.parse(msg.data)
      this.showToast(obj.code, obj.message, 2000)
    },
    send() {

    },
    close() {
      console.log('socket已经关闭')
    },
  },

}
</script>
